.loader, .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.login-btn {
    /* background-color: transparent; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.603) !important;
    font-weight: 300;
    border-radius: 5px;
    border: 1px solid white;
}

.inputs {
    padding-left: 20px !important;
    padding-top: 1.5rem !important;
    background-color: rgba(0, 0, 0, 0.603) !important
}

.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

html {
    height: 100%;
    margin: 0;
}

.login-bg-img {
    background-image: url('../assets/bgImagr.jpg');
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: 100% 100%;
}

/* .login-bg-img::before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background: black;
    z-index: 3040234;
} */

.white {
    color: #ddd;
}

.form-check-label {
    color: #ddd;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd !important;
    opacity: 1;
    /* Firefox */
}

.error {
    color: #D3D3D3;
}

.form-control.pb-4.pl-0.error_border {
    border-color: red !important;
}

.main .logo {
    width: 50%;
}

.box {
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: -76px;
    padding: 15% 20%;
}

.box h1 {
    margin-top: 15%;
}

/*.box .form-group:focus{
   background-color: transparent;
   }*/

.box input, .box input:focus {
    background-color: transparent;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #fff !important;
    box-shadow: none;
    color: #fff;
}

.box input::placeholder {
    color: #fff;
}

.box button, .box button:focus {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px;
    outline: none;
}

@media only screen and (max-width: 767px) {
    .box {
        margin-top: 15px;
        padding: 10%;
    }

    .box h1 {
        margin-top: 0px;
    }
}