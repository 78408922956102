@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200&display=swap);



/*@import '~@geoman-io/leaflet-geoman-free';*/
/*@import "./components/mapReport/Leaflet.Coordinates-0.1.3.css";*/

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', Arial, sans-serif !important;
}

html {
  font-size: 85%;
}

body {
  background-color: #373E43;
  /* background-image: url('./assets/bgbg.jpg'); */
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  /* background-color: rgb(45, 45, 46)!important; */
  min-height: 90vh;
}

p {
  margin: 0;
  padding: 0;
}

/* .top_drawer::-webkit-scrollbar {
  display: none;
} */

nav {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  background-color: rgba(22, 22, 22, 0.295);
}

nav a {
  margin-right: 50px;
  color: white;
  text-decoration: none;
}

a:hover {
  color: white;
  text-decoration: none;
}

h1, h2, h3, h4, h3, h5, h6 {
  margin: 0;
  padding: 0;
}

/* .td-td td:nth-child(even){
background-color: azure!important;
}
.td-td td:nth-child(odd){
background-color: rgb(126, 150, 150)!important;
} */

.drawer-scrollbar::-webkit-scrollbar {
  display: none !important;
}

Button:focus {
  border: none;
  outline: none;
}

Button:active {
  border: none;
  outline: none;
}

/* .Top-bar-div::-webkit-scrollbar-thumb{
background-color: black!important;
} */

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-track {
  background-color: #b6b4b4;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  margin: 5px;
  background: #2e2d2d;
}

.Toastify__toast-theme--light {
  background-color: #141414d2 !important;
  color: rgb(255, 255, 255) !important;
}

.MuiTabs-indicator {
  height: 3px !important;
  background-color: white !important;
}

/* .MuiTab-wrapper{
  border-left: 1px solid white;
}
MuiTab-wrapper:last-child{
  border-left: 1px solid white!important;
}
.MuiTabs-flexContainer:last-child{
  border-left: 1px solid white!important;
} */

.MuiTab-wrapper {
  /* border-left: 1px solid white; */
  border-right: 1px solid white;
  flex-direction: row !important;
  justify-content: space-evenly !important;
  width: 150px !important;
}

.top-drawer-btn {
  min-width: 160px !important;
  display: flex;
  /* padding: 5px; */
  justify-content: space-evenly;
  height: 32px;
  background-color: #151D20;
  min-width: 160px;
  color: white;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  align-items: center;
  border-bottom: 3px solid transparent;
  border-right: 1px solid white;
}

.top-drawer-btn:focus {
  background-color: #373E43;
  border-right: 1px solid white;
}


.top-drawer-btn-1 {
  /* border-bottom: 2px solid white; */
  background-color: #373E43;
  width: 160px !important;
  min-width: 160px;
  border-right: 1px solid white;
}

.top-drawer-btn-active {
  border-bottom: 3px solid rgb(40, 145, 187);
}

.top-drawer-btn:hover {
  background-color: #373E43;
  /* border-bottom: 3px solid rgb(187, 40, 40); */
}

.MuiTableCell-sizeSmall {
  margin: 5px !important;
}



.left-side-drawer {
  flex: 0.4 1;
  min-width: 300px;
}

.makeStyles-appBarShift-3 {
  margin-left: 0px !important;
}

.jss3 {
  margin-left: 0px !important;
}

.jss8 {
  box-shadow: none !important;
}

.right-side-drawer {
  flex: 1 1;
}

.map-table-1 {
  background-color: rgba(45, 49, 58, 0.329);
  /* font-family: 'Open Sans', sans-serif; */
}

.map-table-1:nth-child(even) {
  background-color: rgba(150, 150, 150, 0.329);

}

.map-table-1:nth-child(odd) {
  background-color: rgba(116, 116, 116, 0.329);

}

.map-table-3 {
  background-color: rgba(150, 150, 150, 0.089);
  /* font-family: 'Open Sans', sans-serif; */
}

.btn-map {
  outline: none;
  border: none;
}

.btn-map:focus {
  outline: none;
  border: none;
}

.btn-map-cross {
  background-color: rgba(128, 128, 128, 0.596) !important;
}

.css-10dfkli-MuiTableCell-root {
  color: white !important;
}

.tags {
  transition: all .4s;
  min-width: 300px;
  margin: 0 !important;
}

.tags:nth-child(even) {
  background-color: rgba(116, 116, 116, 0.329) !important;
}

.tags:nth-child(odd) {
  background-color: rgba(150, 150, 150, 0.329) !important;
}

.tags:nth-child(odd):hover {
  background-color: rgba(116, 116, 116, 0.329) !important;
}

.tags:nth-child(even):hover {
  background-color: rgba(150, 150, 150, 0.329) !important;
}

.tag-detail {
  min-width: 50px;
  padding: 5;
  height: 25px;
  background-color: white;
  border-radius: 5px !important;
  font-size: 12px;
  /* font-family: Arial, Helvetica, sans-serif; */
  color: #151D20;
  outline: none;
  border: none;
}

.tag-detail:focus {
  outline: none;
}

@media(max-width:1400px) {
  html {
    font-size: 75%;
  }

  #root>div>div.App>div>div.makeStyles-root-12>header>div>div.MuiTabs-scroller.MuiTabs-scrollable>div {
    height: 40px !important;
  }
}


/* tr:nth-of-type(even) {
  background-color: rgba(45, 49, 58, 0.329) !important;
  border: none !important;
}

tr:nth-of-type(odd) {
  background-color: rgba(45, 49, 58, 0.629) !important;
  border: none !important;
} */

/* tr:nth-of-type(even):hover {
  background-color: rgba(40, 44, 54, 0.589) !important;
  border: none !important;
}

tr:nth-of-type(odd):hover {
  background-color: rgba(45, 49, 58, 0.808) !important;
  border: none !important;
} */

tr {
  transition: all .4s;
}

th {
  border: none !important
}

/* td {
  border: none !important;
  border-left: rgba(255, 255, 255, 0.555) !important;
  font-weight: normal !important;
}

th {
  background-color: rgba(45, 49, 58, 0.929) !important;
} */

.MuiPaper-root {
  background-color: transparent !important;
  border-left: 1px solid gray !important;
}

.MuiTableCell-sizeSmall {
  border-left: 1px solid #ffffff40 !important;
}

.last-scan-select-2 {
  width: 100% !important;
}

.none {
  background: none;
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: black;
  /*background-color: none;*/
  color: white;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-number {
  width: 22px;
  font-size: 14px;
  color: white;
  margin: 3.2px auto;
  text-align: center;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.map-right-side {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  z-index: 20230220 !important;
}

.layers-h1 {
  font-size: 10px;
  color: rgb(10, 10, 10);
  font-weight: bold;
}

.layers {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding: 5px; */
  flex-direction: column;
  border-radius: 70px;
  /* background-color: black; */
  transition: all .4s;
  width: 100%;
  min-width: 70px;
  margin-top: 5px;
  min-height: 70px;
  border: none;
  position: relative;
  outline: none;
  background-color: transparent;
  /* border-bottom: 1px solid white; */
}

.left-side-img {
  box-shadow: -26px 13px 61px -4px rgba(255, 255, 255, 0.47) !important;
}

.layers:focus>.layers-h1 {
  color: rgb(75, 102, 255) !important;
}

.layers:nth-child(first-child) {
  border-top-left-radius: 10px;
}

.layers:hover>.layers-h1 {
  color: rgb(75, 75, 255) !important;
}

.slider-1 {
  background-color: rgb(32, 35, 46);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 21321;
}

.leaflet-container .leaflet-control-mouseposition {
  /*position: absolute;*/
  /*top: 15px;*/
  /*left: 500px;*/
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 5px #bbb;
  padding: 0 5px;
  margin: 0;
  color: #333;
  font: 11px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.MuiDialog-paperWidthSm {
  background-color: #282828 !important;
  color: white !important;
}

.MuiPopover-paper {
  background-color: #151D20 !important;
  color: white !important;
}

.last-scan-select-2 {
  width: 100%;
  /* background-color: black!important; */
}

.input-mat {
  width: 100% !important;
}

.input-mat-1 {
  width: 300px !important;
}

@media(max-width:1400px) {
  .last-scan-select-2 {
    width: 100% !important;
    /* background-color: black!important; */
  }

  .input-mat {
    width: 100% !important;

  }

  .input-mat-1 {
    width: 300px !important;

  }

  .input-mat::-webkit-input-placeholder {
    font-size: 50px !important;
  }

  .input-mat::placeholder {
    font-size: 50px !important;
  }
}

.label-custom {
  color: black;
  font-family: 'Roboto';
  font-size: 12px;
  text-shadow:
    -1.8px -1.8px 0 white,
    0 -1.8px 0 white,
    1.8px -1.8px 0 white,
    1.8px 0 0 white,
    1.8px 1.8px 0 white,
    0 1.8px 0 white,
    -1.8px 1.8px 0 white,
    -1.8px 0 0 white;
  word-spacing: -3px;
  font-weight: 600;

}

.leaflet-touch .leaflet-bar a:first-child {
  display: none !important;
}

.leaflet-touch .leaflet-bar a:last-child {
  display: none !important;
}

body>div:nth-child(10)>div>div {
  z-index: 4523452345 !important;
}

.ant-picker-dropdown {
  z-index: 4523452345 !important;
}

.image-hover:hover {
  -webkit-transform: scale(3);
          transform: scale(3);
  position: absolute;
  z-index: 31239085;
}

.image-hover {

  transition: .4s all;
  position: absolute;
  z-index: 31239085;
}

.ant-checkbox-inner {
  border: 1px solid #b4b4b4 !important;
}

.makeStyles-root-17 {
  background-color: rgb(32 32 32) !important;
}

.counted-class-red {
  background-color: #6e1c1c !important;
  border-bottom: 1px solid #6f6f6f !important;

}

.counted-class-gray {
  background-color: 363636 !important;
  border-bottom: 1px solid #6f6f6f !important;

}

.counted-class-green {
  background-color: #076004 !important;
  border-bottom: 1px solid #6f6f6f !important;

}

.MuiTableContainer-root {
  height: 400px !important;
}
.Top-bar-links {
    border: none;
    outline: none;
    background-color: transparent;
    color: white;
    /* border: 1px solid white; */
    padding: 5px;
    font-size: 11px;
    min-width: 85px;
    padding-right: 20px;
    border-right: 1px solid whitesmoke;

    /* padding-right: 30px; */
    /* border-radius: 5px; */
    letter-spacing: 1px;
    min-height: 30px;
    cursor: pointer;
    margin: 0px 30px;
    /* box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55);
    -webkit-box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55);
    -moz-box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55); */
    transition: .4s;
}

.Top-bar-links-active {
    /* background-color: white; */
    /* color: black; */
    box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    -webkit-box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    -moz-box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    /* border-radius: 5px; */
    /* border: 1px solid black; */
}

.Top-bar-links:hover {
    /* background-color: white; */
    /* color: black; */
    box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    -webkit-box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    -moz-box-shadow: 0px 0px 11px 2px rgba(156, 156, 156, 0.55);
    /* border-radius: 5px; */
    /* border: 1px solid black; */
}

.drawer-active-class {
    /* border: 1px solid whitesmoke; */
    background-color: rgba(0, 0, 0, 0.55);
    padding-left: 10px;
    color: white;
    display: flex!important;
    width: 100%;
}

.drawer-class {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    display: none;
    border-bottom: 1px solid black;
}
.loader, .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.login-btn {
    /* background-color: transparent; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px;
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.603) !important;
    font-weight: 300;
    border-radius: 5px;
    border: 1px solid white;
}

.inputs {
    padding-left: 20px !important;
    padding-top: 1.5rem !important;
    background-color: rgba(0, 0, 0, 0.603) !important
}

.loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

html {
    height: 100%;
    margin: 0;
}

.login-bg-img {
    background-image: url(/static/media/bgImagr.610aca29.jpg);
    margin: 0;
    padding: 0;
    min-height: 100vh;
    position: relative;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: 100% 100%;
}

/* .login-bg-img::before{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    background: black;
    z-index: 3040234;
} */

.white {
    color: #ddd;
}

.form-check-label {
    color: #ddd;
}

::-webkit-input-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd !important;
    opacity: 1;
    /* Firefox */
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ddd !important;
    opacity: 1;
    /* Firefox */
}

.error {
    color: #D3D3D3;
}

.form-control.pb-4.pl-0.error_border {
    border-color: red !important;
}

.main .logo {
    width: 50%;
}

.box {
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: -76px;
    padding: 15% 20%;
}

.box h1 {
    margin-top: 15%;
}

/*.box .form-group:focus{
   background-color: transparent;
   }*/

.box input, .box input:focus {
    background-color: transparent;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #fff !important;
    box-shadow: none;
    color: #fff;
}

.box input::-webkit-input-placeholder {
    color: #fff;
}

.box input::placeholder {
    color: #fff;
}

.box button, .box button:focus {
    background-color: transparent;
    border-radius: 6px;
    border: 1px solid #fff;
    color: #fff;
    padding: 10px;
    outline: none;
}

@media only screen and (max-width: 767px) {
    .box {
        margin-top: 15px;
        padding: 10%;
    }

    .box h1 {
        margin-top: 0px;
    }
}
.main-dashboard {
    display: flex;
    width: '100%';
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
}

.dashboard {
    width: 98%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    border-radius: 10px;
    /* box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55);
    -webkit-box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55);
    -moz-box-shadow: 0px 0px 11px 2px rgba(255, 255, 255, 0.55); */
    border: 1px solid black;
}

.dashboard-heading {
    font-size: 1.5em;
    font-weight: bold;
    color: rgb(37, 37, 37);
}

.dashboard-first-col {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.dashboard-header {
    width: 100%;
    height: 60px;
    background-color: #D3D8DB;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

body, body * {
 
    /* font-weight: lighter; */
}

.dashboard-first-col-box {
    /* width: ; */
    flex: 1 1;
    min-width: 200px;
    min-height: 50px;
    border-radius: 10px;
    background-color: rgb(17, 17, 17);
    display: flex;
    align-items: center;
    padding: 20px;
    margin: 1em;
    justify-content: center;
    flex-direction: column;
}

.scaning-store-heading {
    font-size: 15px;
    margin-top: 1em;

    font-weight: 600;
    color: whitesmoke;
    letter-spacing: 1px;
}

.dashboard-first-col-box-main-heading {
    color: whitesmoke;
 
    letter-spacing: 2px;
}

.dashboard-first-col-box-main-number {
    font-size: 5em;
    color: whitesmoke;
}

.activity-input {
 
    width: 100%;
    background-color: transparent;
    border-radius: 10px;
    border: 1px solid whitesmoke;
    padding: 10px;
    color: whitesmoke;
    letter-spacing: 2px;
    font-size: 15px;
}

.last-scan-select-2 {
    width: 100%;
    /* background-color: black!important; */
}

@media(max-width:1400px) {
    .last-scan-select-2 {
        width: 100%!important;
        /* background-color: black!important; */
    }
    .input-mat {
        width: 100%!important;
    }
    .input-mat-1 {
        width: 100%!important;
    }
}

.text-field-label {
    color: whitesmoke!important;
    border-color: white!important;
}

.activity-input:disabled {
    border: 1px solid whitesmoke;
}

.last-scan-select {
    width: 100%
}
.map-wrapper {
	height: 80vh;
	width: 100%;
	margin: auto;
	margin-top: 5px;
}
.map-wrapper .pin-info {
	text-align: center;
	width: 300px;
	height: 80px;
	color: white;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
	padding: 10px;
	top: 280px;
	right: 10px;
	border-radius: 25px;
}
.map-wrapper .map-layers {
	background-color: rgba(0, 0, 0, 0.5);
	width: auto;
	height: auto;
	position: absolute;
	top: 35%;
	right: 10px;
	display: grid;
	border-radius: 25px;
	padding: 15px;
	color: white;
	font-size: 10px;
}
.map-wrapper .map-layers h3 {
	text-align: center;
	font-weight: bold;
}
.map-wrapper .map-layers button {
	font-size: 10px;
	border: none;
	padding: 6px;
	background-color: #19a9e7;
	border-radius: 10px;
	margin: 5px;
	cursor: pointer;
}
.map-wrapper .map-layers button.selected {
	border: 1px solid white;
}
.map-wrapper .store-menu {
	padding: 10px;
	position: absolute;
	top: 50%;
    left: 0;
	background-color: white;
	width: auto;
	height: auto;
}
/* Side Menu */

.map-side-menu{
	position: absolute;
	left: 20.4vw;
	top: 170px;
	height: 70%;
	min-width: 500px;
	max-width: 500px;
	width: 500px;
	background-color: #373e43;
	color: white;
	z-index: 1;
	padding: 15px;
	border-radius: 5px;
	transition: 1s all;
}

.map-side-menu.hidden{
   left: -500px;
}

.map-side-menu .nav-options{
	display: flex;
    /* gap: 10px; */
    list-style-type: none;
    justify-content: center;
    align-items: center;
	margin: 0;
	padding: 10px;
}
.map-side-menu .nav-options li{
	cursor: pointer;
	min-width: 200px;
	text-align: center;
	/* box-shadow: 2px 2px 10px gray; */
	padding: 5px 10px;
	transition: 1s all;
	font-weight: bold;
    text-transform: capitalize;
	background-color: #161d20;
	border-left: 1px solid rgb(190, 190, 190);
	border-right: 1px solid rgb(190, 190, 190);
}
.map-side-menu .nav-options li:hover{
	box-shadow: 2px 2px 10px gray;
}
.map-side-menu .nav-options li.selected{
	/* color: #19a9e7; */
	/* box-shadow: 2px 2px 10px gray; */
	border-bottom: 1px solid rgb(187, 40, 40);
}

.map-side-menu .hide-menu{
	float: right;
	width: 26px;
	margin-right: -22.8vw;
    margin-top: -60px;
    padding:5px;
    border-radius: 0 20% 20% 0;
    text-align: center;
	font-weight: bold;
	cursor: pointer;
	color: white;
	background-color: #373e43;
}

.map-side-menu .hide-menu.hidden{
	border-radius: 4px;
	margin-right: -5px;
	margin-top: -60px;
}

.map-side-menu .hide-menu:hover{
	/* border: 1.5px solid grey; */
	box-shadow: 2px 2px 10px gray;
}

.map-side-menu .selected-view{
	margin-top: 10px;
	overflow:auto;
	height: 62vh;
}

.map-side-menu .body{
	padding: 20px;
    overflow: auto;
    overflow-x: hidden;
    max-height: 570px;
}

.map-side-menu .map-create-footer{
	margin-top: 15px;
    width: 25%;
    float: right;
}
.nav-info{
	display: grid;
	padding: 10px 10px;
	width: 100%;
}
.nav-info .info-row{
	width: 100%;
	padding: 5px 0;
	/* flex: 0 1 100%;	 */
}
.site-view {
	margin: 0;
}

.site-view li{
	list-style: none;
	padding: 5px 5px;
	font-size: bold;
	cursor: pointer;
}
.site-view li span.delete-button{
	float:right;
}
.site-view li span.delete-button:hover{
	float:right;
	font-size: 14px;
}

.site-view li span.confirm-delete{
	display: block;
	padding: 5px;
}

.site-view li span.confirm-delete .buttons {
	display: flex;
    grid-gap: 5px;
    gap: 5px;
    justify-content: right;
}

.site-view li.selected{
	box-shadow: 1px 1px 5px white;
}

.site-view li:hover, .site-view li.selected{
	box-shadow: 1px 1px 5px white;
}
.create-view-wrapper{
    /* display: flex; */
    /* justify-content: left; */
}

.create-view-wrapper .row{
    /* flex:0 1 100%; */
}

.color-picker-wrapper{
    padding: 8px 10px;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
}

.color-picker-wrapper span{
    width: 20px;
    height: 20px;
    opacity: .6;
    cursor: pointer;
    transition: all 1s;
    border-radius: 4px;
}

.color-picker-wrapper span:hover{
    box-shadow:2px 2px 5px white;
}

.color-picker-wrapper span.selected{
    border: 2px solid white;
}

.zones-creation-view {
    width: 100%;
    display: flex;
    padding: 5px 0px;
}

.zones-creation-view span{
    width: 20px;
    height: 20px;
    opacity: .6;
    transition: all 1s;
    border-radius: 4px;
    margin-left: 5px;
    border: 1px solid black;
}
.zone-to-add{
    padding: 8px;
    border-radius: 5px;
    border: 1px solid white;
    width: 73%;
}
